import React, { useRef, useState } from 'react'
import { useSprings, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import clamp from 'lodash.clamp'
import swap from 'lodash-move'
import styled from 'styled-components'

import { BreakPoint, FontSize } from "../variables"
import { FlashingText, TextGroup } from "../styles/"

import { ConfettiDrop } from "./"

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	@media ${BreakPoint.lg} {
		flex-direction: row;
	}
	ul {
		list-style: disc;
		li {
			margin-bottom: 15px;
			${FontSize.sm}
		}
	}
`
const Question = styled.div`
	${FontSize.sm}
	margin-bottom: 30px;
	@media ${BreakPoint.lg} {
		flex-direction: row;
		width: 350px;
		margin-right: 30px;
	}
	@media ${BreakPoint.xl} {
		width: 450px;
	}
`
const Reorder = styled.div`
	width: 450px;
	max-width: 100%;
	position: relative;
	margin: 0 auto;
`
const Card = styled(animated.div)`
	position: absolute;
	width: 450px;
	max-width: 100%;
	// height: 80px;
	transform-origin: 50% 50% 0px;
	border-radius: 5px;
	color: ${props => props.color ? props.color : 'black'};
	background: ${props => props.bgColour2 ? props.bgColour2 : 'lightblue'};
	&:hover {
		background: ${props => props.bgColour1 ? props.bgColour1 : 'lightblue'};
	}
	line-height: 40px;
	// padding-left: 32px;
	font-size: 14.5px;
	text-transform: uppercase;
	letter-spacing: 2px;
	touch-action: none;
	border: 2px solid black;
	text-align: center;
	user-select: none;
	text-wrap: nowrap;
	overflow-y: scroll;
`
const fn = (order, down, originalIndex, curIndex, y) => (index) =>
  down && index === originalIndex
    ? { y: curIndex * 60 + y, scale: 1.1, zIndex: '1', shadow: 15, immediate: (n) => n === 'y' || n === 'zIndex' }
    : { y: order.indexOf(index) * 60, scale: 1, zIndex: '0', shadow: 1, immediate: false }

export const ReorderableQuestion = () => {

	const [correct, setCorrect] = useState(false)
	const finishedList = [
		'Dasher',
		'Dancer',
		'Prancer',
		'Vixen',
		'Comet',
        'Cupid',
        'Donner',
        'Blitzen'
	];

	const correctItems = [
		{
			index: 0,
			value: 'Dasher',
			revealValue: 'Dasher',
			bgcolour1: "#EF3340",
			bgcolour2: "#BF0D3E",
			color: "white"
		},
		{
			index: 1,
			value: 'Dancer',
			revealValue: 'Dancer',
			bgcolour1: "#EF3340",
			bgcolour2: "#BF0D3E",
			color: "white"
		},
		{
			index: 2,
			value: 'Prancer',
			revealValue: 'Prancer',
			bgcolour1: "#EF3340",
			bgcolour2: "#BF0D3E",
			color: "white"
		},
		{
			index: 3,
			value: 'Vixen',
			revealValue: 'Vixen',
			bgcolour1: "#EF3340",
			bgcolour2: "#BF0D3E",
			color: "white"
		},
		{
			index: 4,
			value: 'Comet',
			revealValue: 'Comet',
			bgcolour1: "#EF3340",
			bgcolour2: "#BF0D3E",
			color: "white"
		},
		{
			index: 5,
			value: 'Cupid',
			revealValue: 'Cupid',
			bgcolour1: "#EF3340",
			bgcolour2: "#BF0D3E",
			color: "white"
		},
		{
			index: 6,
			value: 'Donner',
			revealValue: 'Donner',
			bgcolour1: "#EF3340",
			bgcolour2: "#BF0D3E",
			color: "white"
		},
		{
			index: 7,
			value: 'Blitzen',
			revealValue: 'Blitzen',
			bgcolour1: "#EF3340",
			bgcolour2: "#BF0D3E",
			color: "white"
		}
	];
	const [items, setItems] = useState(Array.from(correctItems.sort(() => Math.random() - 0.5)))

	const order = useRef(items.map((_, index) => index)) // Store indicies as a local ref, this represents the item order
  	const [springs, api] = useSprings(items.length, fn(order.current))
	const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
		const curIndex = order.current.indexOf(originalIndex)
		const curRow = clamp(Math.round((curIndex * 60 + y) / 60), 0, items.length - 1)
		const newOrder = swap(order.current, curIndex, curRow)
		api.start(fn(newOrder, active, originalIndex, curIndex, y)) // Feed springs new style data, they'll animate the view without causing a single render
		if (!active) {
			order.current = newOrder
			checkOrder(order.current)
		} 
	})

	const checkOrder = (currentOrder) => {
		let errorFound = false
		currentOrder.map((item, i) => {
			if(i !== items[item].index) {
				errorFound = true
			} 
		})
		if(!errorFound) {
			setCorrect(true)
		} else {
			setCorrect(false)
		}
	}

	return (
		<Wrapper>
			<Question>
				{correct ?
						<TextGroup center="true">
							<FlashingText center="true">Well done!!</FlashingText>
							{/* <p>Take a look at the their Grand Slam Records</p> */}
						</TextGroup>
						
					:
						<>Can you arrange Santa's Reindeer in the order they appear in Clement Clarke Moore’s classic poem "The Night Before Christmas” ("A Visit from St. Nicholas")?</>
				}
			</Question>
			
			{correct ?
				<>
					<ul>
						{finishedList.map((player, i) => (
							<li key={i}>
								{player}
							</li>
						))}
					</ul>
					<ConfettiDrop/>
				</>
			:
				<Reorder style={{ height: items.length * 60 }}>
					{springs.map(({ zIndex, shadow, y, scale }, i) => (
						<Card 
							{...bind(i)}
							key={i}
							bgColour1={items[i].bgcolour1}
							bgColour2={items[i].bgcolour2}
							color={items[i].color}
							style={{
								zIndex,
								boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
								y,
								scale,
							}}>
							{items[i].value}
						</Card>				
					))}
				</Reorder>
			}
		</Wrapper>
	)
}
