import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { lighten } from 'polished'
import { useCookies } from 'react-cookie';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, updateUser } from '../store/reducers/userSlice';

//general
import { BreakPoint } from "../variables"
import { NavBar } from "../structures"

const ContainerColour = '#000000'
const GlowColour = '#EF3340'
// const GlowColour = '#fedd00'
const ScreenColour = '#007A53'
// const ScreenColour = '#685bc7'
const ScreenColourLight = lighten(0.1, ScreenColour)

const Container = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	background: ${ContainerColour};
	padding: 1rem;
	@media ${BreakPoint.sm} {
		padding: 3rem;
	}
`
const Screen = styled.main`
	position: relative;
	flex-grow: 1;
	overflow: hidden;
	width: 100%;
	max-width: 1200px;
	color: #fff;
	padding: 1rem;
	border-radius: 30px;
	border: 0.6rem solid;
	border-width: 0.2rem;
	border-color: #fff;
	background: ${props => props.bgColour ? props.bgColour : ScreenColour};
	box-shadow: 0 0 .2rem #fff,
				0 0 .2rem #fff,
				0 0 2rem ${GlowColour},
				0 0 0rem ${GlowColour},
				0 0 0rem ${GlowColour};
	color: #fff; 
	@media ${BreakPoint.sm} {
		padding: 3rem;
		border-radius: 50px;
	}
`
const ScreenBackground = styled.img`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
`
const ScreenOverlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 100000000000000000;
	background-image: repeating-linear-gradient(
		transparent,
		transparent 2px,
		${ScreenColourLight} 2px,
		${ScreenColourLight} 4px
	);
	mix-blend-mode: multiply;
	opacity: 0.3;
	pointer-events: none;
`
const ScreenGlow = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 100000000000000000;
	box-shadow: inset 0 0 5rem ${GlowColour};
	pointer-events: none;
`
const ScreenContent = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`
const PageContent = styled.div`
	flex-grow: 1;
	padding: 30px 0px;
	overflow-x: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	@media ${BreakPoint.sm} {
		padding: 40px;
	}
	@media ${BreakPoint.md} {
		padding: 50px;
	}
	@media ${BreakPoint.lg} {
		padding: 60px;
	}
`


export const Page = ({ bgColour, bgImage, glowColour, homeLink, rightIcons, children }) => {

	const [cookies, setCookie] = useCookies(['arcadeUser']);
	const user = useSelector(selectUser);
	const dispatch = useDispatch();

	useEffect(() => {
		if(cookies.arcadeUser) {
			dispatch(updateUser(cookies.arcadeUser))
		} else {
			const newId = Math.random().toString().substring(2, 18);
			setCookie('arcadeUser', newId, { path: '/' });
			dispatch(updateUser(newId))
		}
	}, []);

	return (

		<Container>
			<Screen bgColour={bgColour}>
				{bgImage &&
					<ScreenBackground src={bgImage} />	
				}
				<ScreenContent>
					<PageContent>
						{ children }
					</PageContent>
					<NavBar homeLink={homeLink} rightIcons={rightIcons}/>
				</ScreenContent>
				<ScreenOverlay/>
				<ScreenGlow/>
			</Screen>
		</Container>

	)
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}
