import React from 'react'
import styled from 'styled-components'
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti'

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	overflow: hidden;
`

export const ConfettiDrop = () => {

	const {width, height} = useWindowSize();

	return (
		<Wrapper>
			<Confetti
				width={width}
				height={height}
				gravity={0.3}
				colors={['#e2ae61', '#b4763f', '#f4f1b1']}
				/>
		</Wrapper>
	)
}
