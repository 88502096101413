import { createSlice } from '@reduxjs/toolkit'

import Image from "../../../images/image-puzzle/santa-moped-01.jpg"
import ImageMobile from "../../../images/image-puzzle/santa-moped-mobile-01.jpg"

export const puzzleImageSlice = createSlice({
  name: 'puzzleImage',
  initialState: {
    desktop: {
      width: 400, 
      height: 400,
      url: Image
    },
    mobile: {
      width: 300, 
      height: 300,
      url: ImageMobile
    }
  },
  reducers: {
    updateImageUrl: (state, action) => {
      state.desktop.url = action.payload
    }
  }
})

export const { updateImageUrl } = puzzleImageSlice.actions

export const selectPuzzleImage = state => state.puzzleImage

export default puzzleImageSlice.reducer